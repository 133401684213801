<template>
<div class="home">
    <div class="home-top">
        <SearchTop />
        <Swiper />
    </div>
    <div class="class-nav">
        <router-link to="/catalog/0">
            <span class="specil-tag">热</span>
            <div class="logo-wrap">
                <img src="../assets/yb_navIcon00.png" alt="">
            </div>
            <div class="class-navtext">
                城乡目录
            </div>
        </router-link>
        <router-link to="/catalog/1">
            <div class="logo-wrap">
                <img src="../assets/yb_navIcon01.png" alt="">
            </div>
            <div class="class-navtext">
                职工目录
            </div>
        </router-link>
        <router-link to="/medsecd/1">
            <span class="specil-tag">必看</span>
            <div class="logo-wrap">
                <img style="height:46%" src="../assets/yb_navIcon02.png" alt="">
            </div>
            <div class="class-navtext">
                发票领取
            </div>
        </router-link>
        <router-link to="">
            <div class="logo-wrap">
                <img src="../assets/yb_navIcon03.png" alt="">
            </div>
            <div class="class-navtext">
                医保案列
            </div>
        </router-link>
        <router-link to="/medsecd/0">
            <span class="specil-tag">新</span>
            <div class="logo-wrap">
                <img src="../assets/yb_navIcon04.png" alt="">
            </div>
            <div class="class-navtext">
                缴费查询
            </div>
        </router-link>
    </div>

    <NoticeLine :news="newsList"/>

    <div class="class-stu" style="background:none">
      <router-link to="/public">
        <img class="stu-banner" src="../assets/yb_banner01.png" alt="banner">
      </router-link>
    </div>

    <div class="tabs-name">
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==1}" @click="setTabNameIndex(1)">头条通知</a>
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==2}" @click="setTabNameIndex(2)">医保案例</a>
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==3}" @click="setTabNameIndex(3)">医保代办</a>

    </div>

    <div class="tabs-content">
        <ListItem v-for="newItem in newsList.slice(0,3)" :key="newItem.News_ID" :ids="newItem.News_ID" :title="newItem.News_Title" :reads="newItem.News_Count" :imgsrc="newItem.News_SmallImage" />
        <router-link to="/NewsList/0" class="check-more">查看更多</router-link>
    </div>

    <div class="class-stu">
        <img class="stu-banner" src="../assets/yb_banner02.png" alt="banner">
    </div>

    <!-- <div class="class-tit">
        <p>精彩瞬间</p>
        <router-link to="" class="check-more">查看更多</router-link>
    </div>

    <ListItem title="" reads="" imgsrc="" /> -->

    <NavBottom :checkNum="2" />
</div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      tabNameIndex: 1,
      newsList:[]
    };
  },
  created() {
    this.getNewsList()
  },
  methods: {
    setTabNameIndex(num) {
      this.tabNameIndex = num;
    },
    getNewsList() {
      this.wpost({
        url: "/Ajax/Member/getnewslist",
        data: {
          pagesize: 20,
          pageindex: this.pageNewsIndex,
          sort: 0
        },
        succ: res => {
          console.log("newsList:", res);
          this.newsList = res.list;
        }
      });
    }
  }
};
</script>

<style scoped>
.tabs-content{
    background:#fff;
}
.tabs-content .check-more{
    font-size: 12px;
    padding: 6px 0;
    color: #999
}
.tabs-content .check-more::after,.tabs-content .check-more::before{
    display: none;
}
.specil-tag {
  background-color: #01ac90;
}
.specil-tag::after {
  background-color: #01ac90;
}
.home-top {
  background-color: #f5f5f5;
  background-image: url("../assets/top_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  padding: 5px;
}

/* 五个导航样式 */
.class-nav {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 0;
  margin: 0 5px;
}

.class-nav a {
  position: relative;
  text-decoration: none;
}

.logo-wrap {
  height: 40px;
  width: 40px;
  margin: 3px auto;
  text-align: center;
  border-radius: 50%;
  background-image: linear-gradient(to right, #606cfc, #4692f1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-wrap img {
  height: 60%;
  vertical-align: middle;
}

.class-navtext {
  font-size: 13px;
  font-weight: bold;
  color: #333;
}

/* 两个导航样式 */

.class-stu {
  margin: 10px 5px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 0;
}

.stu-banner {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.tabs-name {
  text-align: center;
  display: flex;
  padding: 5px 0;
}

.tab-item {
  width: 20%;
  font-size: 13px;
  color: #333;
  font-weight: bold;
  position: relative;
  text-decoration: none;
}

.tab-item::after {
  transition: width 300ms ease, border-color 400ms ease;
  transform-origin: center center;
  content: "";
  position: absolute;
  width: 20%;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  bottom: -3px;
  border-bottom: 2px solid transparent;
}

.tab-item.on::after {
  width: 55px;
  border-bottom-color: #fa583f;
}

.class-tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.class-tit p {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.class-tit a {
  font-size: 11px;
  color: #999;
}
</style>
